@import "../../../../shared/scss/partials/variables";

.accordion-row-view{
    display: flex;
    flex-direction: row;
}
.accordion-row-head{
    max-width: 140px;
    color: #9a8e90;
    font-size: 14px !important;
    font-weight:600;
}
.accordion-row-text{
    font-size: 14px !important;
    color: black;
    font-weight:600;
    line-height: 1.75;
    min-width: 10px;
    max-width: 250px;
}
.accordion-view{
    div {
        word-break: break-word;
        overflow-wrap: break-word;
    }
}
.custom-drop-down {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 4% !important;
    min-height: calc(1.5em + 0.79rem);
    max-height: max-content;
    overflow-x: auto;
    font-size: 0.875rem;
    line-height: 1;
    background-color: #ffffff;
    border-radius: 0.25rem;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    font-weight: unset !important;
    > img {
        width: 12px;
        position: absolute;
        right: 6px;
    }
}
.custom-dropdown-menu {
    width: 100%;
    border-color: #2b2b2b;
    border-radius: unset;
    border-width: 1px;
    padding-bottom: unset !important;
    ul {
        border-top: 1px solid $outline;
        max-height: calc(31vh - 16px);
        overflow: hidden auto;
        margin-top: 0.9rem;
        margin-bottom: unset !important;
    }
    .dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
