
.accordion-row-view{
    display: flex;
    flex-direction: row;
}
.accordion-row-head{
    width: 140px;
    color: #9a8e90;
    font-size: 14px !important;
    font-weight:600;
}
.accordion-row-text{
    font-size: 14px !important;
    color: black;
    font-weight:600;
    min-width: 50px;
    max-width: 180px;
}
.accordscroll{
    max-height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
}