.accordion-row-view{
    display: flex;
    flex-direction: row;
}
.accordion-row-head{
    width: 140px;
    color: #9a8e90 !important;
    font-size: 14px !important;
    font-weight:600;
}
.accordion-row-text{
    font-size: 14px !important;
    color: black;
    font-weight:600;
    line-height: 1.75;
    min-width: 130px !important;
    max-width: 250px !important;
}
.accordion-view{
    div {
        word-break: break-word;
        overflow-wrap: break-word;
    }
}