
.accordion-row-view{
    display: flex;
    flex-direction: row;
}
.admin-report {
    .col{
        white-space: nowrap;
    }
}
.accordion-row-head{
    max-width: 140px;
    color: #9a8e90;
    font-size: 14px !important;
    font-weight:600;
}
.accordion-row-text{
    font-size: 14px !important;
    color: black;
    font-weight:600;
    line-height: 1.75;
    min-width: 10px;
    max-width: 250px;
}
.accordion-view{
    div {
        word-break: break-word;
        overflow-wrap: break-word;
    }
}
.users-details{
    font-weight: lighter;
    margin-top: 10px !important;
}
@media (max-width: 425px){
  
    .col {
        flex: 0 0 100% !important
    }
}