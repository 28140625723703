
.invoice-create-container {
    padding-bottom: 5%;

    tr, td, th {
        vertical-align: middle !important;
        // width: 100%;
    }
    tbody {
        word-break: break-all;
        overflow-wrap: break-word;
    }
    overflow-y: auto;
    height: 100%;
    .header {
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        display: flex;
    }
    .header-button {
        margin: 0% 1.5%;
    }
    .splitter {

    }
    .sale-container {
        flex-direction: row;
        display: flex;
    
    }
    .sale-title , .select-sale {
        max-width: 50% !important;
        min-width: 10%;
        margin: 2% 0%;
        margin-left: 5%;
        border-top-right-radius: 0% !important;
        border-bottom-right-radius: 0% !important;
    }
    .searchbutton {
        border-top-right-radius: 5%;
        border-bottom-right-radius: 5%;
        background-color: #00263E;
    }
    .contact-container {
        flex-direction: row;
        display: flex;
        flex: 1;
        justify-content: space-evenly;
        margin: 1% 0%;
    }
    .text-secondary {
        font-weight: bold;
        font-size: 16px;
    }
    p {
        margin: 1.5%;
        width: 100%;
    }

    .email, .mobile {
        color: #1d9aa5;
    }
    .splitter {
        width: 100%;
        height: 1px;
        background-color: #eff0f2;
    }
    .button-container {
        margin-bottom: 6.4%;
        margin-top: 1.44%;
    }
    .select-item {
        // min-width: 200px; */
    min-width: 200px ;
    max-width: 300px ;
    white-space: normal;
    }
    .footer-container {
        margin-top: 3%;
        // margin: 5% 0%;
        .fee-container {
            width: 100%;
            .item-left {
                color: #A50050;
                font-weight: bolder;
            }
            input, div{
                width: 100%;
                justify-content: center;
                margin: 0% 1%;
            }
            button {
                width: 10%;
                border: none;
                background: none;
                align-self: baseline;
            }
            text {
                width: 10%;
                text-align: center;
            }
        }
        .fee-content {
            margin: 1% 0% !important;
            flex-direction: row;
            display: flex;
            justify-content: space-around;
            .total-amount-text {
                font-weight: bolder;
            }
            }
        }
    .add-notes-container {
        width: 100%;
        padding: 1% 2%;
        display: flex;
        flex: 1;
        flex-direction: column;
        textarea {
            width: 100%;
            min-height: 120px;
        }
        .add-notes-content {
            border:1px solid #c0c0c0;
            width: 100%;
            min-height: 100px;
            margin: 1% 2%;
        }
    }
    .remove {
        color: #A50050;
        font-size: 30px;
        font-weight: bolder;
    }
    .billing-header {
        flex-direction: row;
        flex: 1;
        display: flex;
        label {
            width: 70% 
        }
        button {
            // margin-left: -2px;
            border-top-right-radius: 10%;
            border-bottom-right-radius: 10%;
        }
        .form-control {
            border-top-right-radius: 0% !important;
            border-bottom-right-radius: 0% !important;
            width: 100%;
        }
    }
    .item-loader-view {
        text-align: center;
        margin-top: 10%;
        width: 100% !important;
    }
    .input-tag-container {
        flex-direction: row;
        border: 1px solid #c0c0c0;
        display: flex;
        height: fit-content !important;
        margin: 0%;
        border-radius: 4px;
        div {
            width: unset !important;
            height: fit-content !important;
            justify-content: center;
            align-self: center;
            height: 100%;

        }
        .form-control {
            padding: 0% !important;
            margin-left: 5px;
        }
        .form-control:focus, .form-control:disabled {
            border: 0px !important;
            outline: unset !important;
            box-shadow: unset !important;
        }
        input {
            border: 0px ;
            width: 100%;
            flex: 1;
        }
    }
    .table-responsive{
        scrollbar-width: 0px;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    .total-td {
        word-break: break-all;
        overflow-wrap: break-word;
    }
    .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15%;
        width: 100%;
        margin: 2% 5%;
        button {
            border: none;
            background: transparent;
            display: flex;
            flex-direction: row;
            width: 100%;
        }
        .outer-circle {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: #414141 1px solid;
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 0% 1%;
        }
        .inner-circle {
            width: 15px;
            height: 15px;
            background-color: #414141;
            border-radius: 100%;
        }
        button:hover, button:focus {
            border: 0px !important;
            outline: unset !important;
            box-shadow: unset !important;
        }
    }
    .search-sale-list {
        width: 54.3%;
        margin: -1.9% 5%;
        position: absolute;
        background: #f7f7f8;
        z-index: 3;
        border: 1px solid #c0c0c0;
        padding: 1%;
        border-radius: 5px;
        max-height: 50vh;
        overflow-y: auto;
        p {
            width: auto;
            margin: 0px;
            border-top: 1px solid #ced4d9;
            padding: 2% 0%; 
        }
    }
    .biller-view {
        .btn {
            padding: 0% !important;
        }
    }
    .biller-list-view {
        width: 100%;
        // margin-left: 44% !important;
        position: absolute;
        background: #f7f7f8;
        z-index: 1;
        border: 1px solid #c0c0c0;
        padding: 3%;
        border-radius: 5px;
        max-height: 200px;
        overflow-y: auto;
        .biller-item {
            border-top: 1px solid #ced4d9;
            justify-content: space-between;
            padding: 10px 1px;
            p {
                line-height: 12px
            }
        }
        .checkbox {
            width: 25px;
            height: 25px;
            background-color: white;
            border: 1px solid;
            text-align: center;
            i {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .invoice-number-container {
        flex-direction: row;
        display: flex;
        flex: 1;
        justify-content: end;
        margin-bottom: 10px;
        .invoice-number-content {    
            flex-direction: row;
            display: flex;
            width: 32%;
            .invoice-number-left {
                color: #a73550;
                font-weight: bold;
                font-size: 17px;
                text-align: end;
            } 
            .invoice-number-right {
                color: green;
                font-weight: bold;
                font-size: 17px;
        
            }
        }
    }
    .debounce{
        outline: none;
        padding: 10px;
        margin-left: 25px;
        font-weight: 600 !important;
        border: 1px solid #ced4da;
        height: 36px;
    }
    
    .biller-no-data {
        text-align: center;
        font-size: 12px;
        margin-top: 12px;
        color: #9f2240;
        font-weight: bolder;
    }
    .errormessage {
        color: #9f2240;
        font-size: 20px;
        text-align: center;
        font-weight: 500;
    }
    .invoieupdate {
        position: absolute;
        text-align: center;
        width: 80%;
        div { 
            padding: 1%;
            margin: 1% 20%;
            font-weight: bolder;
            font-size: 18px;
            color: green;
        }
    }
    .pickup-view , .billing-view, .shipping-view {
        // width: 50%;
        margin: 0% 5%;
    }
    .preview-pickup-view , .preview-billing-view, .preview-shipping-view {
        // width: 50%;
        margin: 0% 8%;
    }
    .total-amount {
        text-align: end !important;
        font-weight: bolder;
    }

    .istaxexempt {
        color: #c0c0c0 !important;
    }   
}


#previewmodalview {
    .invoice-create-container {
    }
    .contact-container {
        justify-content: center;
    }
    .add-notes-container {
        margin-top: 1%;
        padding: 0% 4%;
        .add-notes-title {
            color: #9f2240;
            font-weight: bolder;
            min-width: 15%;
        }
        .add-notes-content {
          /*   border:1px solid #c0c0c0; */
            width: 100%;
            min-height: 100px;
            margin: 1% 2%;
            padding: 1%;
            word-wrap: break-word;
        }
    }
    .amount-right {
        text-align: end !important;
        width: 100%;
        flex-direction: row;
        display: flex;
        flex: 1;
        .service-amount {
            width: 100%;
            text-align: end;
        }
        .total-amount-text {
            font-weight: bolder;
        }
    }
    .footer-container {
        .add-notes-title {
            padding: 0% 1%;
        }
        .input-add-notes {
            height: 100px;
            width: 70%;
            margin: 1% 2%;
            border: 1px solid #808080;
        }
    }  .fee-container {
        padding: 0% 0%;
        padding-right: 3%;
        margin: 0% !important;
        .item-left {
            color: #A50050;
            font-weight: bolder;
            min-width: 20%; 
        }
         div{
            justify-content: start;
            margin: 0% 2%;
        }
        .fee-remark {
            font-size: 12px;
            min-width: 30%;
            max-width: 60%;
            
        }
    }
    .fee-content {
        width: 100%;
        // margin: 1% 0% !important;
        flex-direction: row;
        display: flex;
        justify-content: space-around;
        border-bottom:  1px solid #eff0f2;
        padding: 1%;
        
    }

}
@media (max-width: 800px){
    .invoice-create-container {
        overflow-y: auto;
        .contact-container {
            flex-direction: column;
            display: flex;
        }
    }
    .invoice-number-container {
        .invoice-number-content {
            width: 100% !important;
        }
    }
}
.table-responsive::-webkit-scrollbar-thumb {
    height: 5px;
  box-shadow:none !important
}
.table-responsive:hover::-webkit-scrollbar {
    height: 5px;
    // background-color: transparent !important;
}
tbody tr td:first-child {
    width: 2%;
    word-wrap: break-word;
}

@media (min-width: 375px) and (max-width: 600px){
    .footer-container {
        margin-bottom: 10% !important;
    }
}