
#change-bidder{
    .modal-content{
    border-radius: 0% !important;
    width: 100%;
    margin: 0% 15%;
}
    .header{  
        font-weight: bolder;
        padding: 20px 0px 0px 20px;
    }
    .bidder-head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .bidder-close{
        margin: 0px 20px;
        font-size: 18px;
        color: gray;
    }
}
.change-bidder-content{
    padding: 0px 10px;
    .table-head , .table-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .bidder-head , .bidderView{
        width: 50%;
    }
    .amount-head {
        width: 40%;
    }
     .amountView{
        width: 60%;
    }
    .amount-head , .bidder-head {
        font-weight: bold;
        color: black;
        align-self: start;
    }
    .table-row{
        margin: 3px 0px;
        padding: 10px 20px;
        background-color: #ececec;
    }
    .active{
        background-color: #cecece;
    }
    .bidderView , .amountView{
        display: flex;
        flex-direction: row;
    }
    .amount-text {
        width: 50%;
        text-align: right;
    } .rank-text{
        width: 50%;
        text-align: center;
    }
    .bidder-text  {
        width: 70%;
    }
    .radio-button-outer {
        width: 20px;
        height: 20px;
        // margin-top:5px #a02040;
        margin-right: 10px;
        border: 1px solid  #7e7777;
        border-radius: 10px;
        padding: 3px;
    }
    .radio-button-inner{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: #a02040;
    }
    .table-boady{
        margin: 10px 0px;
    }
}