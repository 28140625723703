
.accordion-row-view{
    display: flex;
    flex-direction: row;
}
.accordion-row-head{
    width: 140px;
    color: #9a8e90;
}
.accordion-row-text{
    font-size: 13px;
    color: black;
    font-weight:600;
    min-width: 50px;
    max-width: 180px;
}
@media (max-width: 425px) {
   .mt-3{
       text-align: center;
   }
}